import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormCustomer from '@/Models/formCustomer'
import httpMulti from '@/services/http'
import * as Helpers from '@/helpers'
import router from '@/router'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormDependent extends Vue {
  form: FormCustomer = new FormCustomer()
  loading = false
  isAction = 'add'
  isFileEditBirth = false
  isFileEditMarriage = false
  isFileEditFbi = false
  isFileEditPassport = false
  isFileEditPicture = false
  async confirmation() {
    this.loading = true
    const form = this.depedentForm(this.form)
    switch (this.isAction) {
      case 'add':
        await this.save(form)
        break
      case 'edit':
        await this.update(this.form)
        break
      default:
        console.log('ups')
        break
    }
  }
  async save(form: any) {
    try {
      await httpMulti.post(`/api/client/client_residency_dependent/`, form)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.data)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  close() {
    this.form = new FormCustomer()
    router.push({ name: 'customerForm' })
  }
  async update(dependent: any) {
    try {
      await httpMulti.put(
        `/api/client/client_residency_dependent/${1}/`,
        dependent,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
    }
  }
  depedentForm(obj: any) {
    const formData = new FormData()
    const formCustomer = {
      id: obj.id,
      first_name: obj.first_name,
      middle_name: obj.middle_name,
      surname: obj.surname,
      maiden_name: obj.maiden_name,
      marital_status: obj.marital_status,
      passport_number: obj.passport_number,
      date_issuance_passport: Helpers.dateFormat_db(obj.date_issuance_passport),
      date_expiry_passport: Helpers.dateFormat_db(obj.date_expiry_passport),
      country_origin: obj.country_origin,
    }
    formData.append('data', JSON.stringify(formCustomer))

    if (this.isFileEditBirth) {
      formData.append('birth_certificate', obj.birth_certificate)
    }
    if (this.isFileEditFbi) {
      formData.append('fbi_criminal_history', obj.fbi_criminal_history)
    }
    if (this.isFileEditPassport) {
      formData.append('passport_copies', obj.passport_copies)
    }
    if (this.isFileEditPicture) {
      formData.append('passport_size_picture', obj.passport_size_picture)
    }
    return formData
  }
  birth_certificate_upload() {
    this.isFileEditBirth = true
  }
  fbi_criminal_history_upload() {
    this.isFileEditFbi = true
  }
  passport_copies_upload() {
    this.isFileEditPassport = true
  }
  passport_size_picture_upload() {
    this.isFileEditPicture = true
  }
}
